.App {
  background-color: white;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
}

.login-form {
  margin-top: 10%;
}

.sb-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.sb-div-center {
  display:flex;
  justify-content: center;
  align-items: center;
}

.sb-padding-15 {
  padding-left: 15%;
  padding-right: 15%;
}

label {
  position: relative;
  display: flex;
}

label:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 10px;
  bottom: 0;
  width: 25px;
  height: 25px;
}

.icon-input-username:after {
  background: url("./components/assets/ic_username.svg");
}

.icon-input-password:after {
  background: url("./components/assets/ic_password.svg");
}

.navbar img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.navigation {
  display: flex;
}

.navigation button {
  border-radius: 100%;
  margin-left: 10px;
}

.navigation button:after {
  display: none;
}

#sportplaces-screen-container {
  margin-top: 60px;
}

#sportplaces-container {
  background-color: #ebf0eb;
}

.sportplaces-menu {
  display: inline-block;
} 
/* 
#timeline {
  width: 700px;
} */
.timeline-dates-navigation {
  margin-top: 15px;
}

.vis-timeline {
  font-weight: bold !important;
  margin-bottom: 20px !important;
}

.vis-item.vis-range {
  background-color: #fff;
  border-radius: 10px !important;
  border-style: dashed !important;
  border-width: 1px !important;
  border-color: #d0d0d0 !important;
  color: #707070;
  /* min-height: 58px; */
}

.vis-item.vis-range .vis-item-content {
  display: block !important;
  font-weight: bold;
  text-align: center;
  white-space: pre !important;
  /* width: 100%; */
  min-height: 58px;
}

#timeline-slots .vis-item.vis-range .vis-item-content {
  font-size: 28px;
}

.vis-item.vis-range.booked-by-current-user {
  background-color: green;
  color: transparent;
}

.vis-item.vis-range.booked-by-other-user {
  background-color: darkred;
  color: transparent;
}

.vis-grid.vis-vertical.vis-minor {
  border-color: #fff !important;
}

.vis-timeline {
  border: none !important;
}

.vis-panel.vis-center {
  /* margin-right: 10px !important; */
  padding-top: 2px;
  border-top-style: none !important;
  border-left-style: none !important;
}

.vis-panel.vis-top {
  border-left-style: none !important;
}

.vis-panel.vis-left {
  border-top-style: none !important;
  font-weight: normal;
}

.vis-shadow.vis-top {
  box-shadow: none !important;
}

.vis-shadow.vis-bottom {
  box-shadow: none !important;
}

.vis-item.selected-date {
  background-color: #198754 !important;
  color: #fff;
}

.vis-time-axis .vis-text.vis-minor {
  text-align: center;
}

.vis-rolling-mode-btn {
  display: none;
}

.hidden {
  opacity: 0;
}